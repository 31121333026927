import React, { useState } from 'react';
import axios from 'axios';

// 배포할 때
const apiUrl = 'https://port-0-backend-m058iehh84254446.sel4.cloudtype.app';

// // 로컬 컴퓨터에서 실행할 때
// const apiUrl = 'http://localhost:8000';


const plusUrl = `${apiUrl}/plus`;
const getPreviousUrl = `${apiUrl}/get-previous`;

const App = () => {
  const [number, setNumber] = useState('');
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [previousResults, setPreviousResults] = useState([]);

  const handleInputChange = (event) => {
    setNumber(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(plusUrl, { number: parseInt(number, 10) });
      setResult(response.data.result);
      setError(null);
    } catch (err) {
      setError('Error occurred while fetching the data');
      setResult(null);
    }
  };

  const handleFetchPrevious = async () => {
    try {
      const response = await axios.get(getPreviousUrl);
      setPreviousResults(response.data);
      setError(null);
    } catch (err) {
      setError('Error occurred while fetching the previous data');
    }
  };

  return (
    <div>
      <h1>Number Incrementer</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="number"
          value={number}
          onChange={handleInputChange}
          placeholder="Enter a number"
          required
        />
        <button type="submit">Submit</button>
      </form>
      {result !== null && <p>Result: {result}</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <button onClick={handleFetchPrevious}>이전자료 불러오기</button>
      {previousResults.length > 0 && (
        <div>
          <h2>Previous Results:</h2>
          <ul>
            {previousResults.map((res, index) => (
              <li key={index}>
                Number: {res.number}, Result: {res.result}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default App;